import { useSession, useTranslation } from "@lumar/shared";
import { GridColumns } from "@mui/x-data-grid-pro";
import { FilterRuleFormValue } from "../../shared/connection-filtering/types";

import { useAllProjectsColumns } from "./columns/useAllProjectsColumns";
import { AccountProjectsData } from "./data/types";
import { useAccountProjectsData } from "./data/useAccountProjectsData";
import { formatFilters } from "./projects-filter/formatFilters";
import { v4 as uuid } from "uuid";

export interface AccountProjectsTypesResult extends AccountProjectsData {
  title: string;
  noRowsOverlayProps?: Record<string, unknown>;
  noResultsOverlayProps?: Record<string, unknown>;
  columns: GridColumns;
  filter?: FilterRuleFormValue;
}

export function useAccountProjects(): AccountProjectsTypesResult {
  const { t } = useTranslation("projectsList");
  const { account } = useSession();

  const getTitle = (count: number): string => t("allPageTitle", { count });

  const convertedFilters = formatFilters({
    filters: projectFilters,
    metadata: {
      currentBillingPeriod: account.subscription.currentBillingPeriod,
    },
  })._and;

  const data = useAccountProjectsData(convertedFilters);

  const columns = useAllProjectsColumns();

  return {
    ...data,
    title: getTitle(data.projectTotalCount),
    columns,
  };
}

export const projectFilters = [
  {
    id: uuid(),
    metricCode: "schedulesTotalCount",
    predicateKey: "eq",
    predicateValue: true,
  },
  {
    id: uuid(),
    metricCode: "industryCode",
    predicateKey: "isNull",
    predicateValue: false,
  },
  {
    id: uuid(),
    metricCode: "industryCode",
    predicateKey: "isEmpty",
    predicateValue: false,
  },
  {
    id: uuid(),
    metricCode: "crawlingAt",
    predicateKey: "gt",
    predicateValue: "2022-09-28T00:00:00.000Z",
  },
];
