import { BreadcrumbItem, Breadcrumbs } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { AppSkeleton } from "../app-skeleton/AppSkeleton";
import { Routes } from "../routing/routes";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    transform: "none",
    width: "300px",
    height: "26px",
    background: theme.palette.grey[200],
  },
  breadcrumbs: {
    overflow: "hidden",
  },
}));

export interface AppBreadcrumbsProps {
  breacrumbItems?: BreadcrumbItem[];
  loading?: boolean;
}

export function AppBreadcrumbs({
  breacrumbItems: breadcrumbs = [],
  loading,
}: AppBreadcrumbsProps): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const classes = useStyles();

  if (loading) {
    return <AppSkeleton animation="wave" className={classes.skeleton} />;
  }

  const breadcrumbItems: BreadcrumbItem[] = [
    {
      label: "",
      link: Routes.Projects.getUrl({
        accountId,
      }),
      "data-testid": "top-nav-breadcrumb-home",
    },
  ];

  return (
    <Breadcrumbs
      breadcrumbItems={[...breadcrumbItems, ...breadcrumbs]}
      className={classes.breadcrumbs}
    />
  );
}
