import React, { useCallback, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
import {
  GlobeSimple,
  Snackbar,
  Typography,
  useTranslation,
} from "@lumar/shared";
import useLocalStorageState from "use-local-storage-state";
import { getTimeZones, TimeZone as TimeZoneEntry } from "@vvo/tzdb";

export interface TimeZone {
  code: string;
  group: string[];
  name: string;
  longName: string;
  offset: number;
}

const TIME_ZONE_KEY = "user_timezone";

const timeZoneFallbackValue = {
  code: "utc",
  name: "UTC",
  longName: "UTC",
  group: [],
  offset: 0,
};

export const TimeZoneContext = React.createContext<{
  timeZones: TimeZone[];
  timeZone: TimeZone;
  detectedTimeZone: TimeZone;
  setTimeZone: (code: string | undefined) => void;
}>({
  timeZones: [],
  timeZone: timeZoneFallbackValue,
  detectedTimeZone: timeZoneFallbackValue,
  setTimeZone: () => {
    //
  },
});

export function TimeZoneProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { t, i18n } = useTranslation("timezone");
  const { enqueueSnackbar } = useSnackbar();

  const [timeZone, setTimeZone] = useLocalStorageState<{
    savedTimeZone?: string;
    lastTimeZone?: string;
  }>(TIME_ZONE_KEY);

  const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const timeZones = useMemo(() => {
    return getTimeZones().map((timezone) => {
      const abbreviation = `${timezone.abbreviation.replace(
        /[0-9+-]/g,
        "",
      )}${formatOffset(timezone, i18n.language)}`;
      const city = timezone.mainCities[0];
      return {
        code: timezone.name,
        group: timezone.group,
        name: `(${abbreviation}) ${city}`,
        longName: `${abbreviation} (${city}, ${timezone.countryCode})`,
        offset: timezone.currentTimeOffsetInMinutes,
      };
    });
  }, [i18n.language]);

  const getTimeZone = useCallback(
    (code: string): TimeZone | undefined => {
      return timeZones.find((x) => x.group.includes(code));
    },
    [timeZones],
  );

  const activeTimeZone =
    getTimeZone(timeZone?.savedTimeZone ?? detectedTimeZone) ??
    timeZoneFallbackValue;
  const detectedTimeZoneValue =
    getTimeZone(detectedTimeZone) ?? timeZoneFallbackValue;

  const updateLastTimeZone =
    !timeZone?.lastTimeZone || detectedTimeZone !== timeZone.lastTimeZone;
  const newTimeZone =
    Boolean(timeZone?.lastTimeZone) &&
    detectedTimeZone !== timeZone?.lastTimeZone &&
    detectedTimeZone !== timeZone?.savedTimeZone;

  useEffect(() => {
    if (updateLastTimeZone) {
      setTimeZone((state) => ({
        ...(state ?? {}),
        lastTimeZone: detectedTimeZone,
      }));
    }

    const timeZone = getTimeZone(detectedTimeZone);
    if (!newTimeZone || !timeZone) return;
    enqueueSnackbar(
      <Snackbar
        variant="info"
        title={t("notificationTitile", { timezone: timeZone.longName })}
        actions={{
          primaryButtonLabel: t("accept"),
          secondaryButtonLabel: t("cancel"),
          onPrimaryButtonClick: () =>
            setTimeZone((state) => ({
              ...(state ?? {}),
              savedTimeZone: detectedTimeZone,
            })),
        }}
        icon={GlobeSimple}
      >
        <Typography variant="caption">
          {t("notificationDescription")}
        </Typography>
      </Snackbar>,
      { persist: true },
    );
  }, [
    updateLastTimeZone,
    newTimeZone,
    detectedTimeZone,
    setTimeZone,
    enqueueSnackbar,
    t,
    getTimeZone,
  ]);

  return (
    <TimeZoneContext.Provider
      value={{
        timeZones,
        timeZone: activeTimeZone,
        detectedTimeZone: detectedTimeZoneValue,
        setTimeZone: (timeZone) =>
          setTimeZone((state) => ({
            ...(state ?? {}),
            savedTimeZone: timeZone,
          })),
      }}
    >
      {children}
    </TimeZoneContext.Provider>
  );
}

function formatOffset(timezone: TimeZoneEntry, language: string): string {
  if (timezone.currentTimeOffsetInMinutes === 0) return "";

  return ` ${
    timezone.currentTimeOffsetInMinutes > 0 ? "+" : "-"
  }${new Intl.DateTimeFormat(language, {
    timeStyle: "short",
    hourCycle: "h24",
    timeZone: "GMT",
  }).format(Math.abs(timezone.currentTimeOffsetInMinutes * 60 * 1000))}`;
}
