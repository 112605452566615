import {
  InboxDone,
  ListV3,
  ProfileFavoriteV2,
  SearchRight,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { AppContainer } from "../app-container/AppContainer";
import { Hubspot } from "./Hubspot";
import screenshotBackground from "./screenshot-background.svg";
import screenshotWindow from "./screenshot-window.png";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    maxWidth: "1320px",
    height: "775px",
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "row",
    borderRadius: 8,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    [theme.breakpoints.down(1000)]: {
      height: "auto",
    },
  },
  information: {
    width: "45%",
    height: "100%",
    paddingLeft: "37px",
    paddingRight: "37px",
    paddingTop: "27px",
    paddingBottom: "27px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1280)]: {
      width: "60%",
    },
    [theme.breakpoints.down(1000)]: {
      width: "100%",
    },
  },
  screenshot: {
    width: "55%",
    height: "100%",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    background: `url(${screenshotBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    [theme.breakpoints.down(1280)]: {
      width: "40%",
    },
    [theme.breakpoints.down(1000)]: {
      display: "none",
    },
  },
  screenshotWindow: {
    width: "70%",
    margin: "auto",
    display: "block",
  },
  description: {
    marginTop: "12px",
    marginBottom: "12px",
    color: "#374151",
  },
  point: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "6px",
  },
  pointText: {
    marginLeft: "12px",
  },
  form: {
    marginTop: "36px",
  },
}));

export function ImpactMarketingPage(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("marketingPage");
  return (
    <AppContainer>
      <div className={classes.container}>
        <div className={classes.information}>
          <Typography variant="h5SemiBold">{t("title")}</Typography>
          <Typography variant="body1" className={classes.description}>
            {t("description")}
          </Typography>
          <div className={classes.point}>
            <SearchRight />
            <Typography variant="subtitle1" className={classes.pointText}>
              {t("points.1")}
            </Typography>
          </div>
          <div className={classes.point}>
            <ProfileFavoriteV2 />
            <Typography variant="subtitle1" className={classes.pointText}>
              {t("points.2")}
            </Typography>
          </div>
          <div className={classes.point}>
            <InboxDone />
            <Typography variant="subtitle1" className={classes.pointText}>
              {t("points.3")}
            </Typography>
          </div>
          <div className={classes.point}>
            <ListV3 />
            <Typography variant="subtitle1" className={classes.pointText}>
              {t("points.4")}
            </Typography>
          </div>
          <div className={classes.form}>
            <Hubspot />
          </div>
        </div>
        <div className={classes.screenshot}>
          <img src={screenshotWindow} className={classes.screenshotWindow} />
        </div>
      </div>
    </AppContainer>
  );
}
