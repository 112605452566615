import { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSession } from "@lumar/shared";
import { useURLSearchParams } from "../routing/useURLSearchParams";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    margin: 0,
    "& form": {
      width: "100%",
      display: "flex",
      flexFlow: "column",
      "& fieldset": {
        maxWidth: "100%",
      },
    },
    "& fieldset.form-columns-2": {
      display: "flex",
      gap: 8,
    },
    "& .hs-fieldtype-text .input.hs-focus": {
      background: "white",
      boxShadow: `0px 2px 2px rgb(0 0 0 / 5%), 0px 0px 0px 2px ${theme.palette.primary.main}`,
      borderColor: "transparent",
    },
    "& .field": {
      marginBottom: 20,
      color: theme.palette.grey[700],
    },
    "& .input": {
      border: `1px solid ${theme.palette.grey[100]}`,
      height: 36,
      fontSize: theme.typography.pxToRem(14),
      background: "#ffffff",
      boxShadow: "0px 1px 2px rgb(0 0 0 / 5%)",
      boxSizing: "border-box",
      paddingLeft: 10,
      borderRadius: 6,
      paddingRight: 10,
      position: "relative",
      width: "100%",
      color: theme.palette.grey[700],
      cursor: "text",
      display: "inline-flex",
      alignItems: "center",
      fontWeight: 400,
      lineHeight: "1.1876em",
      "&:hover": {
        borderColor: theme.palette.grey[500],
      },
      "& .hs-input": {
        color: theme.palette.grey[700],
        paddingTop: 9.5,
        paddingBottom: 9.5,
        font: "inherit",
        width: "100%",
        border: 0,
        height: "1.1876em",
        margin: 0,
        display: "block",
        padding: "6px 0 7px",
        minWidth: 0,
        background: "none",
        boxSizing: "content-box",
        animationName: "mui-auto-fill-cancel",
        letterSpacing: "inherit",
        animationDuration: "10ms",
        "&:hover": {
          outline: 0,
        },
        "&:focus": {
          outline: 0,
        },
        "&:focus-visible": {
          outline: 0,
        },
      },
    },
    "& .hs_submit": {
      marginLeft: "auto",
      verticalAlign: "baseline",
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0px 1px 2px rgb(0 0 0 / 5%)",
      minWidth: 64,
      boxSizing: "border-box",
      transition: "none",
      borderRadius: 6,
      cursor: "pointer",
      display: "inline-flex",
      position: "relative",
      height: 38,
      "&:hover": {
        backgroundColor: theme.palette.ultraviolet[700],
      },
      "& .actions": {
        height: 38,
      },
      "& .hs-button": {
        padding: "9.5px 16px",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "none",
        textDecoration: "none",
        cursor: "pointer",
        outline: 0,
        margin: 0,
        border: 0,
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: "0.875rem",
        fontWeight: 500,
        fontFamily: "'Inter', sans-serif",
        height: 38,
      },
    },
    "& .submitted-message": {
      border: `1px solid #dfe1e3`,
      borderRadius: 8,
      background: "#fdfdfe",
      "& p": {
        margin: 32,
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(14),
        backgroundColor: "transparent",
        lineHeight: "17px",
        color: theme.palette.primary.main,
      },
    },
    "& .hs-error-msgs": {
      marginTop: 1,
      marginBottom: 0,
      color: theme.palette.red[600],
    },
  },
}));

type WindowWithHubspot = Window &
  typeof globalThis & {
    hbspt?: {
      forms?: {
        create: (args: Record<string, string>) => void;
      };
    };
  };

function hasHubspot(val: WindowWithHubspot): val is WindowWithHubspot {
  return "hbspt" in val;
}

const FORM_CONTAINER_ID = "hubspotForm";

export function Hubspot(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const urlSearchParams = useURLSearchParams();
  const session = useSession();

  if (!urlSearchParams.has("email") && session?.email) {
    urlSearchParams.set("email", session?.email);
    history.replace({ search: urlSearchParams.toString() });
  }

  if (!urlSearchParams.has("utm_content")) {
    urlSearchParams.set("utm_content", "product_led_impact");
    history.replace({ search: urlSearchParams.toString() });
  }

  useEffect(() => {
    const script = document.createElement("script");
    // eslint-disable-next-line fp/no-mutation
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    const messageListener = (event: {
      data: { type: string; eventName: string };
      origin: string;
    }): void => {
      if (
        event.origin != "https://js.hsforms.net" &&
        event.origin != "https://forms.hsforms.com"
      ) {
        console.error("Invalid origin", event.origin);
      }

      if (
        event.data.type === "hsFormCallback" &&
        event.data.eventName === "onFormReady"
      ) {
        const inputs = [...document.getElementsByClassName("hs-input")];
        inputs.forEach((element) => {
          element.addEventListener("focus", function () {
            element.parentElement?.classList.add("hs-focus");
          });
          element.addEventListener("blur", function () {
            element.parentElement?.classList.remove("hs-focus");
          });
        });
      }
    };

    function handleScriptLoad(): void {
      if (hasHubspot(window)) {
        window.hbspt?.forms?.create({
          region: "na1",
          portalId: "7698075",
          formId: "9b173e39-6204-4bd7-89cd-5e6460a3c88f",
          target: `#${FORM_CONTAINER_ID}`,
        });
        window.addEventListener("message", messageListener);
      }
    }

    script.addEventListener("load", handleScriptLoad);

    return () => {
      script.removeEventListener("load", handleScriptLoad);
      document.body.removeChild(script);
      window.removeEventListener("message", messageListener);
      document
        .getElementById(FORM_CONTAINER_ID)
        ?.childNodes.forEach((element) => element.remove());
    };
  }, []);

  return <div id={FORM_CONTAINER_ID} className={classes.root}></div>;
}
