import * as Yup from "yup";
import { ReportTrendItem } from "../../../../../graphql";

const definedTrendValidator = Yup.object().shape({
  basic: Yup.number(),
  crawlId: Yup.number(),
  createdAt: Yup.string(),
});

export function mapCrawlReportTrendToSparklineChartSeries(
  reportTrend: ReportTrendItem[],
): { x: number; y: number }[] {
  // eslint-disable-next-line fp/no-mutating-methods
  return reportTrend
    .filter(
      (
        trend,
      ): trend is {
        basic: number;
        createdAt: string;
        crawlId: number;
      } => {
        return definedTrendValidator.isValidSync(trend);
      },
    )
    .map((trend) => {
      return {
        x: new Date(trend.createdAt).getTime(),
        y: trend.basic,
      };
    })
    .sort((a, b) => a.x - b.x);
}
