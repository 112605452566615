import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { Wrapper } from "./routing/Wrapper";
import { TimeZoneProvider } from "./timezone/TimeZoneProvider";

// Note: this forces the developer to pass the page content as children so that we don't need as many checks in this component
// Otherwise, we would have to check `render` and `component` props, and wrap them if they exist
interface Props extends Omit<RouteProps, "render" | "children" | "component"> {
  children: React.ReactNode;
}

export function RouteWithGuards({
  children,
  ...routeProps
}: Props): JSX.Element {
  return (
    <Route {...routeProps}>
      <Wrapper>
        <TimeZoneProvider>{children}</TimeZoneProvider>
      </Wrapper>
    </Route>
  );
}
