import { ReactNode } from "react";
import { AppIds, useSession } from "@lumar/shared";
import { ImpactMarketingPage } from "./ImpactMarketingPage";

export function ImpactPackageCheck({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const session = useSession();
  const hasImpactPackage =
    session.hasApp(AppIds.impact) || session.isDeepCrawlAdmin;

  return hasImpactPackage ? <>{children}</> : <ImpactMarketingPage />;
}
