import * as Yup from "yup";
import { validate } from "@deepcrawl/lucene-regex-validator";
import { MetricType, ConnectionPredicate } from "../../../graphql";
import { NarrowMetric } from "../types";
import { getPredicateType } from "../helpers";
import { TFunction } from "i18next";

// Note: Explicit return type looks like an overkill in this scenario. - Michal
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function getFilterRuleArrayValidationSchema(
  metrics: NarrowMetric[],
  t: TFunction<"connectionFiltering">,
) {
  function isValueTypeValid(
    types: MetricType[],
  ): (predicateKey: string, metricCode: string) => boolean {
    return (predicateKey: string, metricCode: string) => {
      const predicateType = getPredicateType(metrics, metricCode, predicateKey);
      if (predicateType) {
        return types.includes(predicateType);
      }
      return false;
    };
  }

  return Yup.array()
    .of(
      Yup.object()
        .shape({
          metricCode: Yup.string().required(),
          predicateKey: Yup.string().required(),
          predicateValue: Yup.mixed<number | boolean | string | Date>()
            .when(["predicateKey", "metricCode"], {
              is: isValueTypeValid([MetricType.Decimal]),
              then: () =>
                Yup.number()
                  .typeError(t("validation.number"))
                  .required(t("validation.required")),
            })
            .when(["predicateKey", "metricCode"], {
              is: isValueTypeValid([MetricType.Integer]),
              then: () =>
                Yup.number()
                  .typeError(t("validation.integer"))
                  .integer(t("validation.integer"))
                  .required(t("validation.required"))
                  .min(Number.MIN_SAFE_INTEGER, t("validation.numberMin"))
                  .max(Number.MAX_SAFE_INTEGER, t("validation.numberMax")),
            })
            .when(["predicateKey", "metricCode"], {
              is: isValueTypeValid([MetricType.Bit, MetricType.Boolean]),
              then: () =>
                Yup.mixed()
                  .transform((value) => String(value) === "true")
                  .required(t("validation.required")),
            })
            .when(["predicateKey", "metricCode"], {
              is: isValueTypeValid([MetricType.String]),
              then: () => Yup.string().required(t("validation.required")),
            })
            .when(["predicateKey", "metricCode"], {
              is: isValueTypeValid([MetricType.Date]),
              then: () => Yup.date().required(t("validation.required")),
            })
            .when("predicateKey", {
              is: (predicateVal: ConnectionPredicate) =>
                predicateVal === ConnectionPredicate.MatchesRegex ||
                predicateVal === ConnectionPredicate.NotMatchesRegex,
              then: () =>
                Yup.string().test(
                  "Regex test",
                  t("validation.regex"),
                  (testValue) =>
                    typeof testValue === "string" && validate(testValue).valid,
                ),
            })
            .required(t("validation.required")),
        })
        .required(),
    )
    .required();
}
