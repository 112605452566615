import {
  DocumentNode,
  gql,
  QueryResult,
  UrlServerPagination,
  useQuery,
} from "@lumar/shared";
import {
  EnumType,
  jsonToGraphQLQuery,
  VariableType,
} from "json-to-graphql-query";
import {
  Account,
  CrawlOrderField,
  CrawlTypeMetadata,
  OrderDirection,
  ProjectConnection,
  ProjectConnectionFilterInput,
} from "../../../graphql";

type AccountProjectsQueryResponse = {
  getAccount?: Pick<Account, "projects" | "accountSettings"> & {
    allProjects?: Pick<ProjectConnection, "totalCount">;
  };
  getCrawlTypesMetadata: Array<Pick<CrawlTypeMetadata, "code" | "name">>;
};

export function useAccountProjectsQuery({
  accountId,
  filter,
  pagination,
  baseFilter,
}: {
  accountId: string;
  filter: ProjectConnectionFilterInput;
  pagination: UrlServerPagination["pageInfo"];
  baseFilter: ProjectConnectionFilterInput;
}): QueryResult<AccountProjectsQueryResponse> {
  const document = generateAccountProjectsQuery();

  const response = useQuery<AccountProjectsQueryResponse>(document, {
    variables: {
      accountId,
      filter,
      baseFilter,
      ...pagination,
    },
    fetchPolicy: "no-cache",
  });

  return response;
}

function generateAccountProjectsQuery(): DocumentNode {
  const { projectFields, crawlFields, accountFields, includeTestSuites } =
    getVariantFields();

  const query = {
    query: {
      __name: "AccountProjects",
      __variables: {
        accountId: "ObjectID!",
        baseFilter: "ProjectConnectionFilterInput",
        filter: "ProjectConnectionFilterInput",
        first: "Int",
        last: "Int",
        before: "String",
        after: "String",
        orderBy: "[ProjectOrder!]",
      },
      getAccount: {
        __args: {
          id: new VariableType("accountId"),
        },
        id: true,
        projects: {
          __args: {
            first: new VariableType("first"),
            last: new VariableType("last"),
            after: new VariableType("after"),
            before: new VariableType("before"),
            orderBy: new VariableType("orderBy"),
            filter: new VariableType("filter"),
            includeTestSuites: false,
          },
          totalCount: true,
          pageInfo: {
            hasNextPage: true,
            startCursor: true,
            endCursor: true,
          },
          edges: {
            cursor: true,
            node: {
              id: true,
              isTestSuite: true,
              name: true,
              primaryDomain: true,
              crawlTypes: true,
              lastCrawlStatus: true,
              finishedAt: true,
              crawls: {
                __args: {
                  first: 1,
                  orderBy: {
                    direction: new EnumType(OrderDirection.Desc),
                    field: new EnumType(CrawlOrderField.CreatedAt),
                  },
                },
                edges: {
                  node: {
                    id: true,
                    finishedAt: true,
                    ...crawlFields,
                  },
                },
              },
              lastFinishedCrawl: {
                id: true,
              },
              ...projectFields,
            },
          },
        },
        allProjects: {
          __aliasFor: "projects",
          __args: {
            filter: new VariableType("baseFilter"),
            includeTestSuites: includeTestSuites ?? false,
          },
          totalCount: true,
        },
        ...accountFields,
      },
      getCrawlTypesMetadata: {
        id: true,
        code: true,
        name: true,
      },
    },
  };

  return gql`
    ${jsonToGraphQLQuery(query, {
      pretty: true,
    })}
  `;
}

function getVariantFields(): {
  includeTestSuites?: true;
  projectFields?: Record<string, unknown>;
  crawlFields?: Record<string, unknown>;
  accountFields?: Record<string, unknown>;
} {
  const defaultFields = {
    segmentsTotalCount: true,
    legacyTasks: {
      __args: {
        filter: { fixedAt: { isNull: true } },
      },
      totalCount: true,
    },
    allPagesTotal: true,
    crawlsTotalCount: true,
  };

  return {
    projectFields: defaultFields,
  };
}
