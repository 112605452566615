/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getIn, FormikProps } from "formik";
import { toPath } from "lodash";

export function getProperty(object: any, properties: string): any {
  return toPath(properties).reduce(
    (value, property) => value && value[property],
    object,
  );
}

export interface FormProps {
  formik: FormikProps<unknown>;
  name: string;
}

export function updateIfPropsChanged(
  properties: string[],
): (newProps: any, oldProps: any) => boolean {
  return (newProps: FormProps, oldProps: FormProps) => {
    if (getDefaultFastFieldChanged(newProps, oldProps)) return true;

    if (
      properties.find(
        (property) =>
          getProperty(newProps, property) !== getProperty(oldProps, property),
      )
    )
      return true;

    return false;
  };
}

export function updateIfFieldsChanged(
  fields: string[],
): (newProps: any, oldProps: any) => boolean {
  return (newProps: FormProps, oldProps: FormProps) => {
    if (getDefaultFastFieldChanged(newProps, oldProps)) return true;

    if (
      fields.find(
        (field) =>
          getIn(oldProps.formik.values, field) !==
            getIn(newProps.formik.values, field) ||
          getIn(oldProps.formik.errors, field) !==
            getIn(newProps.formik.errors, field) ||
          getIn(oldProps.formik.touched, field) !==
            getIn(newProps.formik.touched, field),
      )
    )
      return true;

    return false;
  };
}

// This funtion is the FastField's default shouldUpdate function
// https://github.com/jaredpalmer/formik/blob/formik%402.2.6/packages/formik/src/FastField.tsx (line 79)
function getDefaultFastFieldChanged(
  newProps: FormProps,
  oldProps: FormProps,
): boolean {
  return (
    oldProps.name !== newProps.name ||
    getIn(oldProps.formik.values, newProps.name) !==
      getIn(newProps.formik.values, newProps.name) ||
    getIn(oldProps.formik.errors, newProps.name) !==
      getIn(newProps.formik.errors, newProps.name) ||
    getIn(oldProps.formik.touched, newProps.name) !==
      getIn(newProps.formik.touched, newProps.name) ||
    Object.keys(newProps).length !== Object.keys(oldProps).length ||
    oldProps.formik.isSubmitting !== newProps.formik.isSubmitting
  );
}
