import { Box } from "@material-ui/core";
import { CurrentCategoryHealthScoreSection } from "./data-visualization/charts/health-score/CurrentCategoryHealthScoreSection";
import { URLFunnel } from "./data-visualization/charts/url-funnel/URLFunnel";
import { useCrawlOverviewContext } from "../CrawlOverviewContext";
import { Alert, useTranslation } from "@lumar/shared";

export function Dashboard(): JSX.Element | null {
  const { t } = useTranslation("crawlOverview");
  const { data, categoryNotFound } = useCrawlOverviewContext();

  if (categoryNotFound) {
    return <Alert severity="warning">{t("reportCategoryNotFound")}</Alert>;
  }

  return (
    <Box marginTop="14px">
      <CurrentCategoryHealthScoreSection />
      {data?.selectedCategory?.code === "top" ? <URLFunnel /> : null}
    </Box>
  );
}
