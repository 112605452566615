import { Chip, ChipColor } from "@lumar/shared";
import { ProjectsGridRenderCellParams } from "../data/types";
import { CrawlStatus } from "../../../graphql";

function useStatusColor(status?: CrawlStatus): ChipColor {
  switch (status) {
    case CrawlStatus.Errored:
      return "red";
    case CrawlStatus.Finished:
      return "green";
    case CrawlStatus.Crawling:
    case CrawlStatus.Finalizing:
    case CrawlStatus.Discovering:
    case CrawlStatus.Queued:
    case CrawlStatus.Queuing:
    case CrawlStatus.Paused:
      return "yellow";
    case CrawlStatus.Archived:
    case CrawlStatus.Archiving:
    case CrawlStatus.Draft:
    default:
      return "grey";
  }
}

export function StatusCell(
  params: ProjectsGridRenderCellParams,
): JSX.Element | null {
  const status = (function () {
    const { lastCrawlStatus, crawlsTotalCount } = params.row;
    // In crawls older than 2 years, crawlsTotalCount is 0 and for that reason it appears
    // on the draft section. As a way to avoid confussion on why an archived crawl doesn't
    // has crawls in it, we remove the status to display it as a draft.
    if (lastCrawlStatus === CrawlStatus.Archived && crawlsTotalCount < 1)
      return undefined;
    return lastCrawlStatus;
  })();

  const color = useStatusColor(status);
  return status ? (
    <Chip
      color={color}
      label={status}
      data-testid="all-projects-crawl-status-cell"
      style={{ padding: "0 12px" }}
    />
  ) : null;
}
