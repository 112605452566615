import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Button,
  SmileySad,
  Typography,
  useBrand,
  useTranslation,
} from "@lumar/shared";
import LoopIcon from "@material-ui/icons/Loop";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  sadIcon: {
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.red[400],
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(1),
  },
  message: {
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(1),
  },
}));

export function ChartPanelErrorMessage(props: {
  children: string | ReactNode;
  allowRefresh?: boolean;
}): JSX.Element {
  const { t } = useTranslation("charts");
  const classes = useStyles();
  const brand = useBrand();

  return (
    <div className={classes.container}>
      <div>
        {brand.featureAvailability.chartReportSerieColor ? (
          <SmileySad className={classes.sadIcon} />
        ) : null}
        <Typography component="div" variant="body2" className={classes.message}>
          {props.children}
        </Typography>
        {props.allowRefresh ? (
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<LoopIcon />}
              onClick={() => window.location.reload()}
            >
              {t("refreshPage")}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
