import React from "react";
import { makeStyles } from "@material-ui/core";
import { FastField, FastFieldProps } from "formik";
import { get } from "lodash";
import clsx from "clsx";

import {
  FilterRuleFormValue,
  NarrowMetric,
  Predicate,
  PredicateValue,
} from "../types";
import { FilterMetricSelect } from "./FilterMetricSelect";
import { FilterPredicateInput } from "./FilterPredicateInput";
import { FilterPredicateSelect } from "./FilterPredicateSelect";
import {
  getDefaultFormFilter,
  getDefaultPredicate,
  getDefaultPredicateValue,
} from "../helpers";
import { MetricType } from "../../../graphql";
import {
  Button,
  PlusCircleSolid,
  TrashSolid,
  useTranslation,
} from "@lumar/shared";
import { updateIfPropsChanged } from "../../forms/helpers";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "grid",
    gridTemplateColumns:
      "minmax(180px, 300px) minmax(180px, 230px) minmax(180px, 180px) max-content max-content",
    gridGap: theme.spacing(1, 2),
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gridTemplateColumns: "auto min-content",
      maxWidth: 400,
    },
  },
  cell: {
    [theme.breakpoints.down("sm")]: {
      gridColumnEnd: "span 2",
    },
  },
  predicateCell: {
    [theme.breakpoints.down("sm")]: {
      gridColumnEnd: "span 1",
    },
  },
  addCell: {
    [theme.breakpoints.down("sm")]: {
      order: 5,
    },
  },
  removeCell: {
    marginRight: 0,
    [theme.breakpoints.down("sm")]: {
      order: 4,
      gridColumnEnd: "span 1",
    },
  },
  addButton: {
    textTransform: "uppercase",
    paddingTop: theme.spacing(1.1875),
    paddingBottom: theme.spacing(1.1875),
  },
  deleteIconButton: {
    minWidth: "auto",
    padding: theme.spacing(1.1875),
  },
  deleteIcon: {
    fontSize: theme.typography.pxToRem(16),
  },
}));

interface FilterRuleFieldProps {
  metrics: NarrowMetric[];
  replace: (value: FilterRuleFormValue) => void;
  addNew: (value: FilterRuleFormValue) => void;
  remove: () => void;
  onLastRemainingRuleDeleted?: () => void;
  doesOnlyOneFilterExist: boolean;
  allowDeletingLastEmptyRule?: boolean;
  autoFocus?: boolean;
}

function FilterRuleFieldInner({
  field: { value, name },
  form: { isSubmitting, errors, touched, setFieldValue, setFieldTouched },
  metrics,
  addNew,
  replace,
  remove,
  doesOnlyOneFilterExist,
  allowDeletingLastEmptyRule,
  onLastRemainingRuleDeleted,
  autoFocus,
}: FastFieldProps<FilterRuleFormValue> & FilterRuleFieldProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectionFiltering");

  const metric = metrics.find((x) => x.code === value.metricCode);

  const predicates = metric?.connectionPredicates ?? [];
  const predicate = predicates.find((x) => x.code === value.predicateKey);

  const predicateValue = value.predicateValue;

  const predicateValuePath = `${name}.predicateValue`;
  const fieldError = get(errors, predicateValuePath);
  const showError = get(touched, predicateValuePath) && !!fieldError;
  const error = showError ? fieldError : undefined;

  const hasEmptyPredicateValue = !value.predicateValue;
  const canDeleteRule =
    !doesOnlyOneFilterExist ||
    !hasEmptyPredicateValue ||
    allowDeletingLastEmptyRule;

  function handleAdd(): void {
    addNew(getDefaultFormFilter(metrics));
  }

  function handleRemove(): void {
    if (doesOnlyOneFilterExist) {
      replace(getDefaultFormFilter(metrics));
      setFieldTouched(name, false);
      onLastRemainingRuleDeleted?.();
    } else {
      remove();
    }
  }

  function handleMetricChanged(newValue: NarrowMetric): void {
    const newPredicate =
      newValue.connectionPredicates.find((x) => x.code === predicate?.code) ??
      getDefaultPredicate(newValue);

    setFieldValue(
      name,
      {
        ...value,
        metricCode: newValue.code,
        predicateKey: newPredicate?.code,
        predicateValue: getDefaultPredicateValue(
          newPredicate?.type ?? MetricType.String,
        ),
      },
      true,
    );
  }

  function handlePredicateChanged(newValue: Predicate): void {
    const typeChanged = predicate?.type !== newValue.type;

    setFieldValue(
      name,
      {
        ...value,
        predicateKey: newValue.code,
        predicateValue: typeChanged
          ? getDefaultPredicateValue(newValue.type)
          : value.predicateValue,
      },
      true,
    );
  }

  function handlePredicateValueChanged(newValue: PredicateValue): void {
    setFieldValue(
      name,
      {
        ...value,
        predicateValue: newValue,
      },
      true,
    );
  }

  return (
    <div className={classes.row} data-testid="filter-rule-field">
      <div className={classes.cell}>
        <FilterMetricSelect
          metrics={metrics}
          value={metric}
          onChange={handleMetricChanged}
          disabled={isSubmitting}
        />
      </div>
      <div className={classes.cell}>
        <FilterPredicateSelect
          predicates={metric?.connectionPredicates ?? []}
          value={predicate}
          onChange={handlePredicateChanged}
          disabled={isSubmitting}
        />
      </div>
      <div className={clsx(classes.cell, classes.predicateCell)}>
        <FilterPredicateInput
          type={predicate?.type}
          value={predicateValue}
          onChange={handlePredicateValueChanged}
          onBlur={() => setFieldTouched(predicateValuePath)}
          disabled={isSubmitting}
          error={error}
          autoFocus={autoFocus}
        />
      </div>
      <div className={clsx(classes.cell, classes.addCell)}>
        <Button
          onClick={handleAdd}
          disabled={isSubmitting}
          variant="outlined"
          size="small"
          startIcon={<PlusCircleSolid />}
          classes={{ root: classes.addButton }}
          data-testid="add-filter"
        >
          {t("andStatement")}
        </Button>
      </div>
      <div className={clsx(classes.cell, classes.removeCell)}>
        <Button
          onClick={handleRemove}
          disabled={!canDeleteRule || isSubmitting}
          variant="outlined"
          size="small"
          classes={{ root: classes.deleteIconButton }}
          aria-label="Delete"
          data-testid="remove-filter"
        >
          <TrashSolid className={classes.deleteIcon} />
        </Button>
      </div>
    </div>
  );
}

export function FilterRuleField({
  name,
  ...props
}: FilterRuleFieldProps & { name: string }): JSX.Element {
  return (
    <FastField
      name={name}
      doesOnlyOneFilterExist={props.doesOnlyOneFilterExist}
      shouldUpdate={updateIfPropsChanged(["doesOnlyOneFilterExist"])}
    >
      {(fieldProps: FastFieldProps<FilterRuleFormValue>) => (
        <FilterRuleFieldInner {...fieldProps} {...props} />
      )}
    </FastField>
  );
}
