import { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import { Alert, AppLayout } from "@lumar/shared";
import { ErrorBoundary } from "react-error-boundary";
import { AppTopbar, AppTopbarProps } from "./app-topbar/AppTopbar";
import { PageTitleProps } from "./app-topbar/PageTitle";
import { AppBreadcrumbsProps } from "./AppBreadcrumbs";

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: 15,
  },
}));

export interface AppContainerProps {
  children: ReactNode;
  error?: Error;
  titleProps?: PageTitleProps;
  sidebarContentComponent?: React.ComponentType;
  breadcrumbsProps?: AppBreadcrumbsProps;
  topbarProps?: AppTopbarProps;
}

export function AppContainer(props: AppContainerProps): JSX.Element {
  const classes = useStyles();

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      <AppLayout
        app="impact"
        sidebarContentComponent={props.sidebarContentComponent}
      >
        <div className={classes.margin}>
          <AppTopbar
            {...props.topbarProps}
            titleProps={props.titleProps}
            breadcrumbsProps={props.breadcrumbsProps}
          />
          {props.error ? (
            <Alert severity="error">{props.error.message}</Alert>
          ) : (
            <ErrorBoundary FallbackComponent={ChildrenFallback}>
              {props.children}
            </ErrorBoundary>
          )}
        </div>
      </AppLayout>
    </ErrorBoundary>
  );
}

function Fallback(): JSX.Element {
  return <>Something went wrong</>;
}

function ChildrenFallback(): JSX.Element {
  return <>Something went wrong</>;
}
