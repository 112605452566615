import React from "react";
import { FieldArray } from "formik";
import { get } from "lodash";

import { NarrowMetric, FilterRuleFormValue } from "../types";
import { FilterRuleField } from "./FilterRuleField";

interface FilterRuleFieldArrayProps {
  name: string;
  metrics: NarrowMetric[];
  onLastRemainingRuleDeleted?: () => void;
  allowDeletingLastEmptyRule?: boolean;
  autoFocus?: boolean;
}

export function FilterRuleFieldArray(
  props: FilterRuleFieldArrayProps,
): JSX.Element {
  return (
    <div data-testid="filter-rule-array">
      <FieldArray name={props.name}>
        {({ push, replace, remove, form: { values } }) => {
          const rows: FilterRuleFormValue[] = get(values, props.name);
          const doesOnlyOneFilterExist = rows?.length === 1;

          return rows.map((filter, index) => (
            <FilterRuleField
              key={filter.id}
              name={`${props.name}.${index}`}
              metrics={props.metrics}
              replace={(value) => replace(index, value)}
              addNew={(value) => push(value)}
              remove={() => remove(index)}
              doesOnlyOneFilterExist={doesOnlyOneFilterExist}
              allowDeletingLastEmptyRule={props.allowDeletingLastEmptyRule}
              onLastRemainingRuleDeleted={props.onLastRemainingRuleDeleted}
              autoFocus={props.autoFocus}
            />
          ));
        }}
      </FieldArray>
    </div>
  );
}
