/* eslint-disable react/display-name */
import { GridColDef } from "@mui/x-data-grid-pro";
import React from "react";
import { CountCell } from "../cells/CountCell";
import { LastCrawlCell } from "../cells/LastCrawlCell";
import { ProjectCell } from "../cells/ProjectCell";
import { StatusCell } from "../cells/StatusCell";
import { TFunction } from "i18next";
import { makeStyles } from "@material-ui/core";
import { CrawlSourcesCell } from "../cells/CrawlSourcesCell";
import { DateCell } from "../cells/DateCell";
import { ProjectsGridRenderCellParams } from "../data/types";

export const useStyles = makeStyles(() => ({
  clickableCell: {
    padding: "0px !important",
  },
  segmentIcon: {
    marginRight: "7px",
  },
  taskIcon: {
    marginRight: "6px",
  },
  actionsHeader: {
    "& [class*='MuiDataGrid-columnSeparator']": {
      opacity: "0 !important",
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type ColDefactory<T = {}> = (
  props: {
    t: TFunction<"projectsList">;
    classes: ReturnType<typeof useStyles>;
  } & T,
) => Omit<GridColDef, "renderCell"> & {
  renderCell?: (params: ProjectsGridRenderCellParams) => React.ReactNode;
};

export const getNameCol: ColDefactory = ({ t, classes }) => ({
  field: "name",
  headerName: t("tableHeaderProject"),
  renderCell: (params) => {
    return <ProjectCell {...params} />;
  },
  cellClassName: classes.clickableCell,
  flex: 4,
  disableColumnMenu: true,
});

export const getStatusCol: ColDefactory = ({ t }) => ({
  field: "lastCrawlStatus",
  headerName: t("tableHeaderStatus"),
  renderCell: (params) => {
    return <StatusCell {...params} />;
  },
  flex: 1,
  align: "center",
  disableColumnMenu: true,
});

export const getLastCrawlCol: ColDefactory = ({ t }) => ({
  field: "finishedAt",
  headerName: t("tableHeaderLastCrawl"),
  renderCell: (params) => {
    return <LastCrawlCell {...params} />;
  },
  flex: 1,
  disableColumnMenu: true,
});

export const getUrlCountCol: ColDefactory = ({ t, classes }) => ({
  field: "allPagesTotal",
  headerName: t("tableHeaderURLs"),
  renderCell: (params) => {
    return (
      <CountCell params={params} data-testid="all-projects-urls-count-cell" />
    );
  },
  cellClassName: classes.clickableCell,
  align: "right",
  flex: 1,
  disableColumnMenu: true,
});

export const getCrawlsCountCol: ColDefactory = ({ t, classes }) => ({
  field: "crawlsTotalCount",
  headerName: t("tableHeaderCrawls"),
  renderCell: (params) => {
    return (
      <CountCell params={params} data-testid="all-projects-crawls-count-cell" />
    );
  },
  cellClassName: classes.clickableCell,
  align: "right",
  flex: 1,
  disableColumnMenu: true,
});

export const getCreatedCol: ColDefactory = ({ t }) => ({
  field: "createdAt",
  headerName: t("tableHeaderCreated"),
  flex: 2,
  renderCell: (params) => {
    return <DateCell params={params} data-testid="all-projects-created-cell" />;
  },
  disableColumnMenu: true,
});

export const getSourceCol: ColDefactory = ({ t }) => ({
  field: "crawlTypes",
  headerName: t("tableHeaderCrawlSources"),
  renderCell: (params) => <CrawlSourcesCell {...params} />,
  width: 180,
  headerAlign: "center",
  align: "center",
  disableColumnMenu: true,
  sortable: false,
});
