import { useTranslation } from "@lumar/shared";
import { useContext, useMemo } from "react";
import { TimeZone, TimeZoneContext } from "./TimeZoneProvider";

export interface DateFormatter {
  getDate: (
    date: Date,
    options?: {
      dateFormat?: "full" | "long" | "medium" | "short";
    },
  ) => string;
  getDateWithTime: (
    date: Date,
    options?: {
      dateFormat?: "full" | "long" | "medium" | "short";
      timeFormat?: "full" | "long" | "medium" | "short";
    },
  ) => string;
}

export interface TimeZoneResult {
  timeZone: TimeZone;
  timeZoneOffset: number;
  timeZoneOffsetRelative: number;
  timeZones: TimeZone[];
  setTimeZone: (code: string | undefined) => void;
  formatter: DateFormatter;
}

export function useTimeZone(): TimeZoneResult {
  const { i18n } = useTranslation("timezone");

  const { timeZones, timeZone, detectedTimeZone, setTimeZone } =
    useContext(TimeZoneContext);

  const timeZoneOffset = timeZone.offset;
  const timeZoneOffsetRelative = timeZoneOffset - detectedTimeZone.offset;

  const formatter: DateFormatter = useMemo(
    () => ({
      getDate: (date, options) =>
        new Intl.DateTimeFormat(i18n?.language, {
          dateStyle: options?.dateFormat || "medium",
          timeZone: timeZone.code,
        }).format(date),
      getDateWithTime: (date, options) =>
        new Intl.DateTimeFormat(i18n?.language, {
          dateStyle: options?.dateFormat || "medium",
          timeStyle: options?.timeFormat || "short",
          timeZone: timeZone.code,
        }).format(date),
    }),
    [timeZone.code, i18n?.language],
  );

  return {
    timeZone,
    timeZoneOffset,
    timeZoneOffsetRelative,
    timeZones,
    setTimeZone,
    formatter,
  };
}
