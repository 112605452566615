import {
  ArrowLeftSolid,
  categoryIconConfig,
  DocumentReportSolid,
  isNestedNavigationMenuChildItemGroup,
  isNestedNavigationMenuChildItemSelected,
  NestedNavigationMenu,
  NestedNavigationMenuChildItem,
  NestedNavigationMenuParentItem,
  NestedNavigationMenuParentItemBooleanProperty,
  NestedNavigationMenuSection,
  useTranslation,
  ApolloError,
} from "@lumar/shared";
import { Alert } from "@material-ui/lab";
import { useParams } from "react-router-dom";
import { Routes } from "../../shared/routing/routes";
import { useCrawlContext, CrawlReportCategoryTreeNode } from "./CrawlContext";
import { History } from "history";
import { AppSkeleton } from "../../shared/app-skeleton/AppSkeleton";

export function CrawlSidebarMenu(): JSX.Element {
  const { t } = useTranslation("common");
  const { sections, loading, error } = useCrawlSidebarMenuSections();

  if (error) {
    return <Alert severity="error">{t("genericError")}</Alert>;
  }

  if (loading) {
    return (
      <>
        <AppSkeleton animation="wave" height="42px" />
        <AppSkeleton animation="wave" height="42px" />
        <AppSkeleton animation="wave" height="42px" />
        <AppSkeleton animation="wave" height="42px" />
        <AppSkeleton animation="wave" height="42px" />
      </>
    );
  }

  return <NestedNavigationMenu sections={sections} />;
}

function useCrawlSidebarMenuSections(): {
  sections: NestedNavigationMenuSection[];
  loading: boolean;
  error?: ApolloError;
} {
  const { data, errors, loading } = useCrawlContext();
  const { accountId, projectId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  if (loading) {
    return {
      sections: [],
      loading: true,
    };
  }

  if (errors) {
    return {
      sections: [],
      loading: false,
      error: errors[0],
    };
  }

  function getCrawlReportCategoryHref(
    crawlReportCategory: CrawlReportCategoryTreeNode,
  ): string {
    return Routes.Crawl.getUrl({
      accountId: accountId,
      projectId: projectId,
      category: crawlReportCategory.code,
    });
  }

  const otherModulesSections = (data?.crawlReportCategoriesTree ?? [])
    .filter(
      (c) => c.code !== "admin" && c.code !== "custom" && c.code !== "summary",
    )
    .map((reportCategory) => {
      return {
        showDivider: true,
        id: reportCategory.code,
        items: [
          mapOverviewCrawlReportCategoryToNestedNavigationParentMenuItem(
            reportCategory,
            getCrawlReportCategoryHref,
            getCrawlReportCategorySelectedBooleanProperty(),
          ),
          ...reportCategory.nodes.map((childReportCategory) =>
            mapCrawlReportCategoryToNestedNavigationParentMenuItem(
              childReportCategory,
              getCrawlReportCategoryHref,
              getCrawlReportCategorySelectedBooleanProperty(),
            ),
          ),
        ],
      };
    });

  return {
    loading: false,
    sections: [
      {
        id: "all-projects-section",
        items: [
          {
            id: "view-all-projects-item",
            name: "View all projects",
            icon: ArrowLeftSolid,
            href: Routes.Projects.getUrl({
              accountId,
            }),
          },
        ],
      },
      ...otherModulesSections,
    ],
  };
}

function getCrawlReportCategorySelectedBooleanProperty(
  reportTemplateCodeWithTypeCode?: string,
): (
  crawlReportCategory: CrawlReportCategoryTreeNode,
) => typeof isReportCategorySelected | boolean {
  return (crawlReportCategory: CrawlReportCategoryTreeNode) => {
    if (reportTemplateCodeWithTypeCode) {
      return isReportCategorySelectedInReportView(
        crawlReportCategory,
        reportTemplateCodeWithTypeCode,
      );
    }
    return isReportCategorySelected;
  };
}

function mapOverviewCrawlReportCategoryToNestedNavigationParentMenuItem(
  crawlReportCategory: CrawlReportCategoryTreeNode,
  getHref: (crawlReportCategory: CrawlReportCategoryTreeNode) => string,
  crawlReportCategorySelected: (
    crawlReportCategory: CrawlReportCategoryTreeNode,
  ) => NestedNavigationMenuParentItemBooleanProperty,
): NestedNavigationMenuParentItem {
  return {
    id: crawlReportCategory.code,
    name: crawlReportCategory.name + " Overview",
    icon:
      categoryIconConfig.get(crawlReportCategory.code) || DocumentReportSolid,
    open: isParentReportCategoryOpen,
    selected: crawlReportCategorySelected(crawlReportCategory),
    href: getHref(crawlReportCategory),
  };
}

function mapCrawlReportCategoryToNestedNavigationParentMenuItem(
  crawlReportCategory: CrawlReportCategoryTreeNode,
  getHref: (crawlReportCategory: CrawlReportCategoryTreeNode) => string,
  crawlReportCategorySelected: (
    crawlReportCategory: CrawlReportCategoryTreeNode,
  ) => typeof isReportCategorySelected | boolean,
): NestedNavigationMenuParentItem {
  return {
    id: crawlReportCategory.code,
    name: crawlReportCategory.name,
    icon:
      categoryIconConfig.get(crawlReportCategory.code) || DocumentReportSolid,
    open: isParentReportCategoryOpen,
    selected: crawlReportCategorySelected(crawlReportCategory),
    href: getHref(crawlReportCategory),
    children: crawlReportCategory.nodes.map((childReportCategory) => {
      return {
        id: childReportCategory.code,
        name: childReportCategory.name,
        selected: crawlReportCategorySelected(childReportCategory),
        href: getHref(childReportCategory),
      };
    }),
  };
}

function isReportCategorySelectedInReportView(
  category: CrawlReportCategoryTreeNode,
  reportTemplateCodeWithTypeCode: string,
): boolean {
  return !!category.reports.find((report) =>
    reportTemplateCodeWithTypeCode.includes(report.reportTemplate.code),
  );
}

function isReportCategorySelected(
  item: NestedNavigationMenuChildItem | NestedNavigationMenuParentItem,
  history: History,
): boolean {
  const hrefUrl = new URL(item.href || "", window.location.origin);
  return (
    hrefUrl.pathname === history.location.pathname &&
    hrefUrl.searchParams.get("category") ===
      new URLSearchParams(history.location.search).get("category")
  );
}

function isParentReportCategoryOpen(
  parent: NestedNavigationMenuParentItem,
  history: History,
): boolean {
  return (
    isReportCategorySelected(parent, history) ||
    !!parent.children?.find((child) => {
      return isNestedNavigationMenuChildItemGroup(child)
        ? !!child.children.find((child) =>
            isNestedNavigationMenuChildItemSelected(child, history, parent),
          )
        : isNestedNavigationMenuChildItemSelected(child, history, parent);
    })
  );
}
