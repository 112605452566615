import React from "react";
import {
  FilterRuleFormValue,
  FilterButton,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Popper, ClickAwayListener } from "@material-ui/core";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { ConnectionPredicate } from "../../../graphql";
import { ProjectsFilter } from "./ProjectsFilter";
import { useProjectFilters } from "./useProjectFilters";
import { getFilterRuleArrayValidationSchema } from "../../../shared/connection-filtering/filter-rule-field-array/getFilterRuleArrayValidationSchema";
import { v4 as uuid } from "uuid";
import { isEqual } from "lodash";
import PopperJs from "popper.js";
import * as Yup from "yup";
import { useSearchParam } from "../../../shared/routing/useSearchParam";
import { useURLSearchParams } from "../../../shared/routing/useURLSearchParams";

const useStyles = makeStyles((theme) => ({
  filterPopper: {
    zIndex: theme.zIndex.drawer,
  },
  filterBackdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
}));

const defaultFilter = {
  id: uuid(),
  metricCode: "nameOrDomain",
  predicateKey: ConnectionPredicate.Contains,
  predicateValue: "",
};

export function FiltersButton(): JSX.Element {
  const { t } = useTranslation("projectsList");
  const { t: tFilter } = useTranslation("connectionFiltering");
  const classes = useStyles();
  const URLFilters = useSearchParam("filter");
  const parsedFilters = URLFilters && JSON.parse(unescape(atob(URLFilters)));
  const searchParams = useURLSearchParams();
  const history = useHistory();

  const [localFilterAnchor, setLocalFilterAnchor] =
    React.useState<HTMLButtonElement | null>(null);
  const localFilterVisible = Boolean(localFilterAnchor);
  const localFilterPopperId = localFilterVisible
    ? "local-filter-popper"
    : undefined;
  const localFilterPopperRef = React.useRef<PopperJs | null>(null);

  const initialValues: {
    filters: FilterRuleFormValue[];
  } = {
    filters: URLFilters ? parsedFilters.filters : [defaultFilter],
  };

  const getFilterCount = (filters: unknown[]): number =>
    filters.length === 1 && isEqual(filters[0], defaultFilter)
      ? 0
      : filters.length;

  const filterCount = URLFilters ? getFilterCount(parsedFilters.filters) : 0;

  const projectMetricsWithPredicates = useProjectFilters();

  const validationSchema = React.useMemo(
    () =>
      projectMetricsWithPredicates
        ? Yup.object({
            filters: getFilterRuleArrayValidationSchema(
              projectMetricsWithPredicates,
              tFilter,
            ),
          })
        : undefined,
    [projectMetricsWithPredicates, tFilter],
  );

  const resetFilters = (): void => {
    searchParams.delete("filter");
    searchParams.delete("pagination");
    history.push({ search: searchParams.toString() });
  };

  return (
    <>
      <FilterButton
        label={t("filtersButton")}
        data-testid="filter-toggle"
        onClick={(event) => setLocalFilterAnchor(event.currentTarget)}
        chipLabel={filterCount}
        onClear={
          filterCount
            ? (e) => {
                e.stopPropagation();
                resetFilters();
              }
            : undefined
        }
      />
      <Popper
        id={localFilterPopperId}
        open={localFilterVisible}
        anchorEl={localFilterAnchor}
        placement="bottom-start"
        modifiers={{ flip: { enabled: false } }}
        popperRef={localFilterPopperRef}
        className={classes.filterPopper}
      >
        <ClickAwayListener onClickAway={() => setLocalFilterAnchor(null)}>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              enableReinitialize
              onSubmit={async (values) => {
                const validatedValues =
                  await validationSchema?.validate(values);
                searchParams.delete("filter");
                searchParams.set(
                  "filter",
                  btoa(escape(JSON.stringify(validatedValues ?? values))),
                );
                history.push({ search: searchParams.toString() });
              }}
            >
              {({ submitForm, resetForm, isValid, dirty }) => (
                <ProjectsFilter
                  isValid={isValid}
                  dirty={dirty}
                  submitForm={() => {
                    setLocalFilterAnchor(null);
                    searchParams.delete("pagination");
                    history.push({ search: searchParams.toString() });
                    return submitForm();
                  }}
                  resetForm={resetForm}
                  resetFilters={resetFilters}
                />
              )}
            </Formik>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
