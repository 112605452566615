import {
  getEntityRawId,
  getRawProjectId,
  useSession,
  LoadingScreen,
} from "@lumar/shared";
import { Redirect } from "react-router-dom";
import { useGetFirstProjectQuery } from "../../graphql";

import { Routes } from "../../shared/routing/routes";
import { useGetLastVisitedProject } from "../project/useStoreLastVisitedProject";
import { formatFilters } from "../projects/projects-filter/formatFilters";
import { projectFilters } from "../projects/useAccountProjects";

export function RootPage(): JSX.Element {
  const { account } = useSession();

  const accountId = getEntityRawId(account.id);

  const lastVisitedProjectSaved = useGetLastVisitedProject();
  const lastVisitedProject =
    lastVisitedProjectSaved?.accountId === accountId
      ? lastVisitedProjectSaved
      : undefined;

  const convertedFilters = formatFilters({
    filters: projectFilters,
    metadata: {
      currentBillingPeriod: account.subscription.currentBillingPeriod,
    },
  })._and;

  const { data: firstProjectData, loading: firstProjectLoading } =
    useGetFirstProjectQuery({
      variables: { accountId, filters: { _and: convertedFilters } },
      skip: Boolean(lastVisitedProject),
    });

  if (!accountId) {
    return <>Unknown account</>;
  }

  if (firstProjectLoading) {
    return <LoadingScreen />;
  }

  if (lastVisitedProject) {
    return (
      <Redirect
        to={Routes.Crawl.getUrl({
          accountId,
          projectId: lastVisitedProject.projectId,
        })}
      />
    );
  }

  const projectIdFromQuery =
    firstProjectData?.getAccount?.projects?.edges[0]?.node?.id;

  if (projectIdFromQuery) {
    return (
      <Redirect
        to={Routes.Crawl.getUrl({
          accountId,
          projectId: getRawProjectId(projectIdFromQuery),
        })}
      />
    );
  }

  return (
    <Redirect
      to={Routes.Projects.getUrl({
        accountId,
      })}
    />
  );
}
