import Skeleton, { SkeletonProps } from "@material-ui/lab/Skeleton";
import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

type AdditionalProps = {
  background?: string;
  waveGradientIntensity?: string;
  borderTopLeftRadius?: boolean;
  borderTopRightRadius?: boolean;
  borderBottomLeftRadius?: boolean;
  borderBottomRightRadius?: boolean;
};

const useStyles = makeStyles<Theme, AdditionalProps>((theme) => {
  function getBorder(prop?: boolean): number | undefined {
    if (prop) {
      return theme.shape.borderRadius;
    }
  }

  return {
    root: {
      background: (props) => props.background || undefined,
      borderTopRightRadius: (props) => getBorder(props.borderTopRightRadius),
      borderTopLeftRadius: (props) => getBorder(props.borderTopLeftRadius),
      borderBottomLeftRadius: (props) =>
        getBorder(props.borderBottomLeftRadius),
      borderBottomRightRadius: (props) =>
        getBorder(props.borderBottomRightRadius),
    },
    wave: {
      "&::after": {
        background: (props) =>
          props.waveGradientIntensity
            ? `linear-gradient(90deg, transparent, rgba(0, 0, 0, ${props.waveGradientIntensity}), transparent)`
            : `linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent)`,
      },
    },
  };
});

type AppSkeletonProps = SkeletonProps & AdditionalProps;

export function AppSkeleton(props: AppSkeletonProps): JSX.Element {
  const {
    waveGradientIntensity,
    background,
    borderBottomLeftRadius = true,
    borderTopLeftRadius = true,
    borderBottomRightRadius = true,
    borderTopRightRadius = true,
    ...skeletonProps
  } = props;

  const classes = useStyles({
    waveGradientIntensity,
    background,
    borderBottomLeftRadius,
    borderTopLeftRadius,
    borderBottomRightRadius,
    borderTopRightRadius,
  });

  return (
    <Skeleton
      classes={{ root: classes.root, wave: classes.wave }}
      className={clsx(classes.customSkeleton, props.className)}
      {...skeletonProps}
    />
  );
}
