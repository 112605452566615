import DateFnsUtils from "@date-io/date-fns";
import {
  AuthPage,
  AuthProvider,
  getRawAccountId,
  GQLClientProvider,
  SessionProvider,
  SnackbarProvider,
  ChurnZeroProvider,
  useDocumentTitle,
  useTranslation,
} from "@lumar/shared";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { NotFound } from "./pages/not-found";
import { CrawlPage } from "./pages/project";
import { AccountProjects } from "./pages/projects/AccountProjects";
import { RootPage } from "./pages/root";
import { RouteWithGuards } from "./shared/RouteWithGuards";
import { Routes } from "./shared/routing/routes";
import { TagsController } from "./shared/tags-controller/TagsController";

export const App = (): JSX.Element => {
  const { t } = useTranslation("navigation");

  useDocumentTitle(t("title"));

  return (
    <BrowserRouter>
      <AuthProvider app="impact">
        <GQLClientProvider
          name="impact"
          version={process.env.REACT_APP_BUILD_HASH}
        >
          <SessionProvider
            onSessionError={{
              message: "Something went wrong when establishing a session.",
              actions: [{ name: "Reload", onClick: () => location.reload() }],
            }}
          >
            <ChurnZeroProvider>
              <>
                <TagsController />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <SnackbarProvider>
                    <Switch>
                      <Redirect
                        exact
                        from="//*"
                        to={{
                          pathname: "/*",
                          search: window.location.search,
                        }}
                      />
                      <Route exact path="/">
                        <RootPage />
                      </Route>
                      <RouteWithGuards exact path={Routes.Projects.ROUTE}>
                        <AccountProjects />
                      </RouteWithGuards>
                      <RouteWithGuards exact path={Routes.Crawl.ROUTE}>
                        <CrawlPage />
                      </RouteWithGuards>
                      <Route exact path={Routes.Login.ROUTE}>
                        <AuthPage
                          onSession={(session, history) => {
                            const accountId = getRawAccountId(
                              session.account.id,
                            );
                            return history.replace(
                              Routes.Projects.getUrl({ accountId }),
                            );
                          }}
                        />
                      </Route>
                      <Route>
                        <NotFound />
                      </Route>
                    </Switch>
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </>
            </ChurnZeroProvider>
          </SessionProvider>
        </GQLClientProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
