import { BlueDataGrid, useAccountGuard, useTranslation } from "@lumar/shared";
import { useHistory, useParams } from "react-router-dom";
import { AppContainer } from "../../shared/app-container/AppContainer";
import { Routes } from "../../shared/routing/routes";
import { AccountProjectsContext } from "./data/useAccountProjectsContext";
import { FiltersButton } from "./projects-filter/FiltersButton";
import { useAccountProjects } from "./useAccountProjects";

export function AccountProjects(): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("projectsList");
  const history = useHistory();

  useAccountGuard();

  const {
    title,
    loading,
    error,
    columns,
    projects,
    projectCount,
    projectTotalCount,
    pagination,
    ...contextData
  } = useAccountProjects();

  return (
    <AppContainer
      titleProps={{
        title,
        loading: loading,
      }}
    >
      <AccountProjectsContext.Provider value={contextData}>
        <div data-testid="all-projects-table">
          <BlueDataGrid
            key="all"
            error={error}
            loading={loading}
            columns={columns}
            rows={projects}
            rowCount={projectCount}
            totalRowCount={projectTotalCount}
            autoRowHeight
            rowHeight={70}
            disableColumnResize
            disableColumnReorder
            sortingOrder={["desc", "asc", null]}
            components={{
              ToolbarLeft: FiltersButton,
            }}
            componentsProps={{
              noRowsOverlay: {
                icon: NoProjectsIcon,
                title: t("noProjectsInAccount.title"),
                detail: t("noProjectsInAccount.description"),
              },
              noResultsOverlay: {
                icon: NoProjectsIcon,
                title: t("noProjectsFound.title"),
                detail: t("noProjectsFound.description"),
                onClick: () =>
                  history.push(Routes.Projects.getUrl({ accountId })),
                buttonText: t("clearFilterButton"),
              },
            }}
            {...pagination}
          />
        </div>
      </AccountProjectsContext.Provider>
    </AppContainer>
  );
}

function NoProjectsIcon(): JSX.Element {
  return (
    <svg
      width="66"
      height="41"
      viewBox="0 0 66 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginBottom: "20px" }}
    >
      <rect y="0.172409" width="6" height="40" fill="#FC5193" />
      <rect x="12" y="12.3463" width="6" height="27.8261" fill="#FFAC4F" />
      <rect x="36" y="22.7811" width="6" height="17.3913" fill="#FFAC4F" />
      <rect x="60" y="7.12893" width="6" height="33.0435" fill="#FFAC4F" />
      <rect x="24" y="0.172417" width="6" height="40" fill="#FC5193" />
      <rect x="48" y="0.172417" width="6" height="40" fill="#FC5193" />
    </svg>
  );
}
