import { FaviconIcon, Typography, useTranslation } from "@lumar/shared";
import { Box, makeStyles, Tooltip } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../../shared/routing/routes";
import { Project, ProjectsGridRenderCellParams } from "../data/types";

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: "0 7px",
    width: "100%",
    height: "100%",
    textDecoration: "none",
    display: "inline-block",
    lineHeight: "normal",
    color: "inherit",
    "&:hover": {
      color: "inherit",
      backgroundColor: theme.palette.grey[200],
      cursor: "pointer",
    },
  },
  tooltipPlacement: {
    bottom: 20,
  },
  name: {
    color: theme.palette.grey[800],
  },
  domain: {
    color: theme.palette.grey[600],
    lineHeight: 1.66,
  },
  faviconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 26,
    width: 26,
    background: "white",
    filter:
      "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05)) drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))",
    borderRadius: 4,
  },
  favicon: {
    padding: "2px",
    borderRadius: 4,
  },
}));

export function ProjectCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element | null {
  const classes = useStyles();

  const { text, href } = useProjectNavigationData(row);

  return (
    <Tooltip
      title={text}
      placement="bottom"
      classes={{ tooltipPlacementBottom: classes.tooltipPlacement }}
    >
      <Link
        to={href}
        className={classes.cell}
        data-testid="all-projects-project-link"
        data-pendo="a-project"
      >
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          data-testid="all-projects-project-cell"
        >
          <Box
            marginLeft="20px"
            marginRight="20px"
            display="flex"
            height="100%"
            alignItems="center"
          >
            <Tooltip title={row.primaryDomain} placement="bottom" arrow>
              <div className={classes.faviconDiv}>
                <FaviconIcon
                  url={row.primaryDomain}
                  width={24}
                  height={24}
                  className={classes.favicon}
                />
              </div>
            </Tooltip>
          </Box>

          <Box>
            <Typography
              variant="subtitle2SemiBold"
              className={classes.name}
              data-testid="project-name-label"
              component="div"
            >
              {row.name}
            </Typography>
            <Typography
              variant="caption"
              className={classes.domain}
              data-testid="project-domain-label"
              component="div"
            >
              {row.primaryDomain}
            </Typography>
          </Box>
        </Box>
      </Link>
    </Tooltip>
  );
}

function useProjectNavigationData(project: Project): {
  text: string;
  href: string;
} {
  const { accountId } = useParams<{ accountId: string }>();
  const projectId = project.id;
  const { t } = useTranslation("projectsList");

  // const noCrawls = project.crawlsTotalCount === 0;
  // if (noCrawls) {
  //   return {
  //     text: t("goToProjectSettings"),
  //     href: "",
  //     href: Routes.Crawls.getUrl({
  //       accountId,
  //       projectId,
  //       tab: "edit",
  //     }),
  //   };
  // }

  // const isLatestCrawlFinished =
  //   project.lastCrawlStatus === CrawlStatus.Finished;
  // if (isLatestCrawlFinished && project.lastFinishedCrawlId) {
  //   return {
  //     text: t("goToTheLatestCrawlOverview"),
  //     href: "",
  //     href: Routes.CrawlOverview.getUrl({
  //       accountId,
  //       projectId,
  //       crawlId: project.lastFinishedCrawlId,
  //       type: "dashboard",
  //     }),
  //   };
  // }

  // const isLatestCrawlArchived =
  //   project.lastCrawlStatus === CrawlStatus.Archived;
  // if (isLatestCrawlArchived) {
  //   return {
  //     text: t("goToPastCrawls"),
  //     href: "",
  //     href: Routes.Crawls.getUrl({
  //       accountId,
  //       projectId,
  //       tab: "history",
  //     }),
  //   };
  // }

  // return {
  //   text: t("goToTheCurrentCrawlProgress"),
  //   href: "",
  //   href: Routes.Crawls.getUrl({
  //     accountId,
  //     projectId,
  //     tab: "progress",
  //   }),
  // };

  // TODO: Uncomment this section when the rest of the crawl overview is implemented

  return {
    text: t("goToTheLatestCrawlOverview"),
    href: Routes.Crawl.getUrl({
      accountId,
      projectId,
    }),
  };
}
