import { AppBar, makeStyles } from "@material-ui/core";
import { AppBreadcrumbs, AppBreadcrumbsProps } from "../AppBreadcrumbs";
import { AccountMenu } from "./account-menu/AccountMenu";
import { PageTitle, PageTitleProps } from "./PageTitle";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "unset",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1.25),
  },
  contentTop: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  contentBottom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(1.1),
  },
  title: {
    marginRight: theme.spacing(2),
    overflow: "hidden",
  },
  rightSide: {
    marginLeft: "auto",
  },
  divider: {
    background: theme.palette.grey[300],
    height: 1,
    marginBottom: theme.spacing(3),
  },
}));

export interface AppTopbarProps {
  titleProps?: PageTitleProps;
  breadcrumbsProps?: AppBreadcrumbsProps;
  showDivider?: boolean;
}

export function AppTopbar(props: AppTopbarProps): JSX.Element {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="relative" elevation={0}>
      <div className={classes.content}>
        <div className={classes.contentTop}>
          <div>
            <AppBreadcrumbs {...props.breadcrumbsProps} />
          </div>
          <div className={classes.rightSide}>
            <AccountMenu />
          </div>
        </div>
        {props.titleProps ? (
          <div className={classes.contentBottom}>
            <div className={classes.title}>
              <PageTitle {...props.titleProps} />
            </div>
          </div>
        ) : null}
      </div>
      {props.showDivider ? <div className={classes.divider} /> : null}
    </AppBar>
  );
}
