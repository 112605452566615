import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import {
  AccountOptions,
  getRawAccountId,
  useSession,
  useTranslation,
} from "@lumar/shared";

import { useNavigationMenuItems } from "./useNavigationMenuItems";

const useStyles = makeStyles(() =>
  createStyles({
    accountLogo: {
      maxHeight: "100%",
    },
  }),
);

export function AccountMenu(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("navigation");

  const session = useSession();

  const menuItems = useNavigationMenuItems();

  const logo = session.account.customLogo;

  return (
    <AccountOptions
      accountName={session.account.name}
      accountId={t("accountId", {
        accountId: getRawAccountId(session.account.id),
      })}
      email={
        session.isUsingSharedLink ? t("guest") : session.email || session.id
      }
      role={
        session.isDeepCrawlAdmin
          ? t("deepCrawlAdmin")
          : session.role.name?.toLocaleUpperCase()
      }
      logo={logo && <img src={logo} className={classes.accountLogo} />}
      menuItems={menuItems}
      data-testid="navigation-account"
      menuProps={{
        "data-testid": "navigation-menu",
      }}
    />
  );
}
