import React from "react";
import { ImpactPackageCheck } from "../marketing-page/ImpactPackageCheck";

interface Props {
  children: React.ReactNode;
}

export function Wrapper({ children }: Props): JSX.Element {
  return (
    <>
      <ImpactPackageCheck>
        <main>{children}</main>
      </ImpactPackageCheck>
    </>
  );
}
