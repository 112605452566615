import React from "react";
import { AccountProjectsContextData } from "./types";

export const AccountProjectsContext =
  React.createContext<AccountProjectsContextData>({
    crawlTypesMetadata: [],
  });

export function useAccountProjectsContext(): AccountProjectsContextData {
  return React.useContext(AccountProjectsContext);
}
