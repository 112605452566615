import { generatePath } from "react-router-dom";
import queryString, { StringifyOptions, UrlObject } from "query-string";

export interface RouteParams {
  accountId: string;
}

const ACCOUNTS_ROUTE_MATCH = "/accounts/:accountId";

function createAccountsURL(
  object: Omit<UrlObject, "query"> & Required<Pick<UrlObject, "query">>,
  options?: StringifyOptions,
): string {
  // eslint-disable-next-line fp/no-mutation
  object.query.referral = window.location.href;

  return `${process.env.REACT_APP_ACCOUNTS_APP_URL}${queryString.stringifyUrl(
    object,
    options,
  )}`;
}

export const AccountsRoutes = {
  Login: {
    ROUTE: "/login",
    getUrl(): string {
      return createAccountsURL({ url: AccountsRoutes.Login.ROUTE, query: {} });
    },
  },
  ConnectedApps: {
    ROUTE: ACCOUNTS_ROUTE_MATCH + "/connected-apps",
    getUrl({ accountId }: RouteParams): string {
      const url = generatePath(AccountsRoutes.ConnectedApps.ROUTE, {
        accountId,
      });
      return createAccountsURL({ url, query: {} });
    },
  },
  Account: {
    ROUTE: ACCOUNTS_ROUTE_MATCH,
    getUrl({ accountId }: RouteParams): string {
      const url = generatePath(AccountsRoutes.Account.ROUTE, { accountId });
      return createAccountsURL({ url, query: {} });
    },
  },
  User: {
    ROUTE: ACCOUNTS_ROUTE_MATCH + "/user",
    getUrl({ accountId }: RouteParams): string {
      const url = generatePath(AccountsRoutes.User.ROUTE, { accountId });
      return createAccountsURL({ url, query: {} });
    },
  },
  Team: {
    ROUTE: ACCOUNTS_ROUTE_MATCH + "/team",
    getUrl({ accountId }: RouteParams): string {
      const url = generatePath(AccountsRoutes.Team.ROUTE, { accountId });
      return createAccountsURL({ url, query: {} });
    },
  },
  Subscription: {
    ROUTE: ACCOUNTS_ROUTE_MATCH + "/subscription",
    getUrl({ accountId }: RouteParams): string {
      const url = generatePath(AccountsRoutes.Subscription.ROUTE, {
        accountId,
      });
      return createAccountsURL({ url, query: {} });
    },
  },
  CreditUsage: {
    ROUTE: ACCOUNTS_ROUTE_MATCH + "/credit-usage",
    getUrl({ accountId }: RouteParams): string {
      const url = generatePath(AccountsRoutes.CreditUsage.ROUTE, { accountId });
      return createAccountsURL({ url, query: {} });
    },
  },
  ApiAccess: {
    ROUTE: ACCOUNTS_ROUTE_MATCH + "/api-access",
    getUrl({ accountId }: RouteParams): string {
      const url = generatePath(AccountsRoutes.ApiAccess.ROUTE, { accountId });
      return createAccountsURL({ url, query: {} });
    },
  },
};
