import { useTranslation } from "@lumar/shared";
import { GridColumns } from "@mui/x-data-grid-pro";
import React from "react";
import * as columns from "./getColumn";
import { ColDefactory, useStyles } from "./getColumn";

export function useAllProjectsColumns(): GridColumns {
  const classes = useStyles();
  const { t } = useTranslation("projectsList");

  return React.useMemo<GridColumns>(() => {
    function getColumns(): [ColDefactory[]] {
      const defaultColumns = [
        columns.getNameCol,
        columns.getStatusCol,
        columns.getLastCrawlCol,
        columns.getUrlCountCol,
        columns.getCrawlsCountCol,
        columns.getSourceCol,
      ];

      return [defaultColumns];
    }

    const [columnFactories] = getColumns();

    const columnProps: Parameters<ColDefactory>[0] = { t, classes };

    const columnsDefs = columnFactories.map((factory) => factory(columnProps));

    return columnsDefs as GridColumns;
  }, [classes, t]);
}
