import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Form, FormikProps } from "formik";
import { useTranslation } from "react-i18next";

import { FilterFormValues } from "./types";
import { useProjectFilters } from "./useProjectFilters";
import { FilterRuleFieldArray } from "../../../shared/connection-filtering/filter-rule-field-array/FilterRuleFieldArray";
import { useSpring, animated } from "react-spring";

type FormProps = FormikProps<FilterFormValues>;
interface Props {
  dirty: FormProps["dirty"];
  isValid: FormProps["isValid"];
  submitForm: FormProps["submitForm"];
  resetForm: FormProps["resetForm"];
  resetFilters: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2, 2, 0, 2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  submitButton: {
    minWidth: 180,
    marginLeft: "auto",
  },
}));

export function ProjectsFilter(props: Props): JSX.Element {
  const { submitForm, resetForm, resetFilters } = props;

  const projectFilters = useProjectFilters();

  const classes = useStyles();

  const { t } = useTranslation("projectsList");

  const springProps = useSpring({
    from: { opacity: 0, marginTop: -20, marginLeft: 10 },
    to: { opacity: 1, marginTop: -5, marginLeft: 10 },
    config: { duration: 150 },
  });

  return (
    <Form>
      <animated.div style={springProps}>
        <Paper>
          <Box p={2}>
            <Box pb={2}>
              <FilterRuleFieldArray
                name="filters"
                metrics={projectFilters}
                allowDeletingLastEmptyRule
                autoFocus
              />
            </Box>
            <Box className={classes.actions}>
              <Button
                variant="text"
                onClick={() => {
                  resetForm();
                  resetFilters();
                }}
              >
                {t("clearFilterButton")}
              </Button>
              <Button
                disabled={!props.dirty || !props.isValid}
                onClick={submitForm}
                type="submit"
                variant="contained"
                color="secondary"
                data-testid="project-filter-apply-button"
                classes={{ root: classes.submitButton }}
              >
                {t("applyFilterButton")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </animated.div>
    </Form>
  );
}
