import { generatePath } from "react-router-dom";
import queryString, { StringifyOptions, UrlObject } from "query-string";
import { get } from "lodash";

function createURL(object: UrlObject, options?: StringifyOptions): string {
  return queryString.stringifyUrl(object, options);
}

const ROUTE_MATCHER_PREFIX = `/accounts/:accountId([1-9]\\d*)`;

export const Routes = {
  Login: {
    ROUTE: "/login",
  },
  Projects: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/projects",
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.Projects.ROUTE, { accountId });
      return createURL({ url, query: {} });
    },
  },
  Crawl: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/projects/:projectId([1-9]\\d*)",
    getUrl({
      accountId,
      projectId,
      category,
    }: {
      accountId: string;
      projectId: string;
      category?: string;
    }): string {
      const url = generatePath(Routes.Crawl.ROUTE, {
        accountId,
        projectId,
      });
      return createURL({ url, query: { category } });
    },
  },
};

export const ExternalRoutes = {
  CrawlOverview: {
    ROUTE:
      ROUTE_MATCHER_PREFIX +
      "/projects/:projectId([1-9]\\d*)/crawls/:crawlId([1-9]\\d*)",
    getUrl({
      accountId,
      projectId,
      crawlId,
      type,
      category,
      changes,
    }: {
      accountId: string;
      projectId: string;
      crawlId: string;
      type?: string | null;
      category?: string | null;
      changes?: string | null;
    }): string {
      const path = generatePath(ExternalRoutes.CrawlOverview.ROUTE, {
        accountId,
        projectId,
        crawlId,
      });

      const query = {
        type,
        category,
        changes,
      };

      return createURL({
        url: process.env.REACT_APP_ANALYZE_APP_URL + path,
        query,
      });
    },
  },
  Report: {
    ROUTE:
      ROUTE_MATCHER_PREFIX +
      "/projects/:projectId([1-9]\\d*)/crawls/:crawlId([1-9]\\d*)/reports/:reportTemplateCodeWithTypeCode",
    getUrl({
      accountId,
      projectId,
      crawlId,
      reportTemplateCode,
      reportTypeCode,
    }: {
      accountId: string;
      projectId: string;
      crawlId: string;
      reportTypeCode: string;
      reportTemplateCode: string;
    }): string {
      const reportTemplateCodeWithTypeCode = [
        reportTemplateCode,
        reportTypeCode,
      ].join("_");

      const path = generatePath(ExternalRoutes.Report.ROUTE, {
        accountId,
        projectId,
        crawlId,
        reportTemplateCodeWithTypeCode,
      });

      return createURL({
        url: process.env.REACT_APP_ANALYZE_APP_URL + path,
        query: {},
      });
    },
  },
  Crawls: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/projects/:projectId([1-9]\\d*)/crawls",
    getUrl(
      props: { accountId: string; projectId: string } & (
        | { tab: "edit"; step?: number }
        | { tab: "progress"; start?: boolean; unarchive?: string }
        | { tab: "history"; unarchive?: string }
      ),
    ): string {
      const { accountId, projectId, tab } = props;
      const path = generatePath(ExternalRoutes.Crawls.ROUTE, {
        accountId,
        projectId,
      });

      const step = get(props, "step");
      const start = get(props, "start");
      const unarchive = get(props, "unarchive");

      const query = {
        tab: tab && String(tab),
        step: step && String(step),
        unarchive: unarchive && String(unarchive),
        start: start ? true : undefined,
      };

      return createURL({
        url: process.env.REACT_APP_ANALYZE_APP_URL + path,
        query,
      });
    },
  },
};
