import React from "react";
import { Typography, useTranslation } from "@lumar/shared";
import { Tooltip, useTheme } from "@material-ui/core";
import { ProjectsGridRenderCellParams } from "../data/types";
import { useTimeZone } from "../../../shared/timezone/useTimeZone";

export function LastCrawlCell({
  row,
}: ProjectsGridRenderCellParams): JSX.Element | null {
  const theme = useTheme();
  const { t } = useTranslation("units");
  const { formatter } = useTimeZone();

  return (
    <Tooltip
      title={row.finishedAt ? formatter.getDateWithTime(row.finishedAt) : ""}
      arrow
    >
      <div data-testid="all-projects-finished-at-cell">
        <Typography
          variant="caption"
          style={{ color: theme.palette.grey[500] }}
        >
          {row.finishedAt ? t("timeAgo", { date: row.finishedAt }) : "-"}
        </Typography>
      </div>
    </Tooltip>
  );
}
