import { getRawProjectId } from "@lumar/shared";
import React from "react";
import useLocalStorageState from "use-local-storage-state";
import { useParams } from "react-router-dom";
import { useCrawlContext } from "./CrawlContext";

const LAST_VISITED_PROJECT = "lastVisitedProject";

export function useStoreLastVisitedProject(): void {
  const { data, errors } = useCrawlContext();
  const { accountId } = useParams<{ accountId: string }>();

  const projectId = data?.crawlProject.id
    ? getRawProjectId(data.crawlProject.id)
    : undefined;

  const [, setIds] = useLocalStorageState<{
    projectId: string;
    accountId: string;
  }>(LAST_VISITED_PROJECT);

  React.useEffect(() => {
    if (errors || !projectId || !accountId) return;
    setIds({ projectId, accountId });
  }, [projectId, accountId, errors, setIds]);
}

export function useGetLastVisitedProject():
  | {
      projectId: string;
      accountId: string;
    }
  | undefined {
  const [ids] = useLocalStorageState<{
    projectId: string;
    accountId: string;
  }>(LAST_VISITED_PROJECT);

  return ids ? ids : undefined;
}
