import { Redirect, useParams } from "react-router-dom";
import { AppContainer } from "../../shared/app-container/AppContainer";
import { Routes } from "../../shared/routing/routes";
import { useStoreLastVisitedProject } from "./useStoreLastVisitedProject";
import { Dashboard } from "./dashboard/Dashboard";
import { CrawlSidebarMenu } from "./CrawlSidebarMenu";
import { CrawlContextProvider, useCrawlContext } from "./CrawlContext";
import {
  CrawlOverviewContextProvider,
  useCrawlOverviewContext,
} from "./CrawlOverviewContext";
import { useTranslation } from "@lumar/shared";

export function CrawlPage(): JSX.Element {
  return (
    <CrawlContextProvider>
      <CrawlOverviewContextProvider>
        <CrawlPageInner />
      </CrawlOverviewContextProvider>
    </CrawlContextProvider>
  );
}

function CrawlPageInner(): JSX.Element {
  const { t } = useTranslation("crawlOverview");

  const {
    data: crawlData,
    loading: crawlLoading,
    errors: crawlErrors,
  } = useCrawlContext();

  const {
    data: overviewData,
    loading: overviewLoading,
    errors: overviewErrors,
  } = useCrawlOverviewContext();

  const { accountId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  useStoreLastVisitedProject();

  if (crawlErrors?.length || overviewErrors?.length) {
    return (
      <Redirect
        to={Routes.Projects.getUrl({
          accountId,
        })}
      />
    );
  }

  const selectedCategoryName = overviewData
    ? t("pageTitle", { category: overviewData.selectedCategory.name })
    : "";

  return (
    <AppContainer
      error={crawlErrors?.[0] ?? overviewErrors?.[0]}
      titleProps={{
        title: selectedCategoryName,
        loading: crawlLoading || overviewLoading,
        projectName: crawlData?.crawlProject.name,
      }}
      breadcrumbsProps={{
        breacrumbItems: overviewData?.breadcrumbs,
        loading: crawlLoading || overviewLoading,
      }}
      sidebarContentComponent={CrawlSidebarMenu}
    >
      <Dashboard />
    </AppContainer>
  );
}
