import { MetricType, ConnectionPredicate } from "../../graphql";
import {
  NarrowMetric,
  FilterRuleFormValue,
  FilterOrRuleFormValue,
  Predicate,
} from "./types";
import { v4 as uuid } from "uuid";

export function getPredicateType(
  metrics: NarrowMetric[],
  metricCode: string,
  predicateCode: string,
): MetricType | undefined {
  return metrics
    .find((m) => m.code === metricCode)
    ?.connectionPredicates.find((predicate) => predicate.code === predicateCode)
    ?.type;
}

export function getDefaultPredicate(
  metric: NarrowMetric | undefined,
): Predicate | undefined {
  return (
    metric?.connectionPredicates?.find(
      (x) => x.code === ConnectionPredicate.Contains,
    ) ||
    metric?.connectionPredicates?.find(
      (x) => x.code === ConnectionPredicate.Eq,
    ) ||
    metric?.connectionPredicates?.[0]
  );
}

export function getDefaultPredicateValue(
  predicateType: MetricType,
): string | boolean | Date {
  switch (predicateType) {
    case MetricType.Boolean:
    case MetricType.Bit:
      return true;
    case MetricType.Date:
      return new Date();
    default:
      return "";
  }
}

export function getDefaultFormFilter(
  metrics: NarrowMetric[],
): FilterRuleFormValue {
  // FIXME: getDefaultFormFilter should be a prop that customizing connection filtering behavior.
  // Now it selects two possible default metrics so report and project filtering have nice defaults.
  const defaultMetric =
    metrics.find(
      (metric) => metric.code === "nameOrDomain" || metric.code === "url",
    ) || metrics[0];
  const defaultPredicate = getDefaultPredicate(defaultMetric);

  return {
    id: uuid(),
    metricCode: defaultMetric?.code || "",
    predicateKey: defaultPredicate?.code || "",
    predicateValue: defaultPredicate
      ? getDefaultPredicateValue(defaultPredicate.type)
      : "",
  };
}

export function getDefaultFormOrFilter(
  metrics: NarrowMetric[],
): FilterOrRuleFormValue {
  return {
    id: uuid(),
    _and: [getDefaultFormFilter(metrics)],
  };
}

export function mapFilterRuleFormValuesToNames(
  metrics: NarrowMetric[],
  metricCode: string,
  predicateCode: string,
  predicateValue: string | number | Date | boolean,
  formatDate: (date: Date) => string,
): {
  metricName: string;
  predicateName: string;
  predicateValue: string;
} {
  const metric = metrics.find((metric) => metric.code === metricCode);
  const metricName = metric?.name ?? metricCode;

  const predicate = metric?.connectionPredicates.find(
    (predicate) => predicate.code === predicateCode,
  );
  const predicateName = predicate?.name ?? predicateCode;

  const predicateType = getPredicateType(metrics, metricCode, predicateCode);

  const formattedPredicateValue =
    predicateType === MetricType.Date
      ? formatDate(
          predicateValue instanceof Date
            ? predicateValue
            : new Date(String(predicateValue)),
        )
      : String(predicateValue);

  return {
    metricName,
    predicateName,
    predicateValue: formattedPredicateValue,
  };
}
