import {
  ArrowNarrowRightSolid,
  ChipColor,
  ArrowNarrowDownSolid,
  ArrowNarrowUpSolid,
  Chip,
  SparklineTableChart,
  SparklineTableChartDatum,
  ChartContainer,
  useTranslation,
  useNumberFormatter,
  ChartButton,
  SparklineTableChartProps,
} from "@lumar/shared";
import { useTheme } from "@material-ui/core";
import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import {
  CrawlContextValue,
  useCrawlContextData,
  useCrawlContextHelpers,
} from "../../../CrawlContext";
import { useCrawlOverviewContextData } from "../../../CrawlOverviewContext";
import { mapCrawlReportTrendToSparklineChartSeries } from "./helpers";
import { ChartPanelSuccessMessage } from "./ChartPanelSuccessMessage";
import { ExternalRoutes } from "../../../../../shared/routing/routes";
import { insertIf } from "../../../../../shared/utils/insertIf";

export function TopErrorsChartPanel(): JSX.Element {
  const { t } = useTranslation("charts");

  const { selectedSegment } = useCrawlContextData();

  return (
    <ChartContainer
      title={t("issues.title")}
      description={t("issues.description")}
      subtitle={selectedSegment?.name}
      data-testid="top-issues-chart"
    >
      <TopErrorsChart />
    </ChartContainer>
  );
}

export function TopErrorsChart(): JSX.Element {
  const { t } = useTranslation("charts");
  const helpers = useCrawlContextHelpers();
  const { selectedCategory } = useCrawlOverviewContextData();
  const errorReports = helpers.getCrawlReportCategoryErrorReportsList(
    selectedCategory.code,
  );
  const sparklineTableChartData = useMapTopErrorsToSparklineTableChartData(
    helpers.getCrawlReportCategoryErrorReportsList(selectedCategory.code),
  );

  if (errorReports.length === 0) {
    return (
      <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
        <ChartPanelSuccessMessage>
          {t("issues.noIssuesFound")}
        </ChartPanelSuccessMessage>
      </div>
    );
  }

  return (
    <SparklineTableChart
      {...sparklineTableChartData}
      getMinText={(min) => t("min", { value: min })}
      getMaxText={(max) => t("max", { value: max })}
      linkProps={(_, index) =>
        index === 0 ? { "data-pendo": "top-health-score-error" } : {}
      }
    />
  );
}

export function TopErrorsButton(): JSX.Element {
  const { t } = useTranslation("charts");
  const helpers = useCrawlContextHelpers();
  const crawlData = useCrawlContextData();
  const { projectId, accountId } = useParams<{
    accountId: string;
    projectId: string;
  }>();
  const { selectedCategory } = useCrawlOverviewContextData();
  const errorReports = helpers.getCrawlReportCategoryErrorReportsList(
    selectedCategory.code,
  );
  if (!errorReports.length) return <></>;
  return (
    <ChartButton
      component="a"
      href={ExternalRoutes.CrawlOverview.getUrl({
        accountId,
        crawlId: crawlData.crawl.rawID,
        projectId,
        category: selectedCategory.code,
        type: "errors",
      })}
      size="medium"
      endIcon={<ArrowNarrowRightSolid />}
      data-testid="more-url-button"
    >
      {t("issues.viewAll", { count: errorReports.length })}
    </ChartButton>
  );
}

function useMapTopErrorsToSparklineTableChartData(
  reports: Required<CrawlContextValue>["data"]["crawlReports"],
): {
  data: SparklineTableChartDatum[];
  columns: SparklineTableChartProps["columns"];
} {
  const crawlData = useCrawlContextData();
  const { projectId, accountId } = useParams<{
    accountId: string;
    projectId: string;
  }>();

  const reportsToShow = reports.slice(0, 6).map((report) => ({
    report,
    series: mapCrawlReportTrendToSparklineChartSeries(report.trend),
  }));

  const showPercentageChangeOverall = !!reportsToShow.find(
    ({ series }) => series.length > 1,
  );

  return {
    data: reportsToShow.map(({ report, series }) => {
      const showPercentageChangeReport = series.length > 1;
      return {
        id: report.reportTemplate.code,
        href: ExternalRoutes.Report.getUrl({
          accountId,
          crawlId: crawlData.crawl.rawID,
          projectId,
          reportTemplateCode: report.reportTemplate.code,
          reportTypeCode: "basic",
        }),
        label: report.reportTemplate.name,
        values: {
          rowsChange: (
            <ErrorReportTotalRows
              key="rows-change"
              totalRows={report.basic || 0}
            />
          ),
          percentageChange: (
            <ErrorReportChange
              key="percentage-change"
              totalRows={report.basic || 0}
              change={report.change || 0}
              hidden={!showPercentageChangeReport}
            />
          ),
        },
        series,
      };
    }),
    columns: [
      "name",
      { field: "rowsChange", columnWidth: "minmax(min-content, 1fr)" },
      ...insertIf(showPercentageChangeOverall, {
        field: "percentageChange",
        columnWidth: "minmax(min-content, 1fr)",
      }),
      "trend",
    ],
  };
}

function ErrorReportTotalRows(props: { totalRows: number }): JSX.Element {
  const theme = useTheme();
  const formatNumber = useNumberFormatter();
  return (
    <span
      key="total-rows"
      style={{
        color: theme.palette.red[600],
        width: "34px",
        textAlign: "right",
        display: "inline-block",
        fontWeight: 600,
      }}
    >
      {formatNumber(props.totalRows, {
        notation: "compact",
      })}
    </span>
  );
}

function ErrorReportChange(props: {
  totalRows: number;
  change: number;
  hidden?: boolean;
}): JSX.Element {
  const formatNumber = useNumberFormatter();
  const percentageChange = Math.abs(
    props.totalRows / (props.totalRows - props.change) - 1,
  );

  return (
    <Chip
      {...useChipProps(props.change)}
      style={{
        minWidth: "86px",
        alignSelf: "start",
        visibility: props.hidden ? "hidden" : undefined,
      }}
      label={formatNumber(percentageChange, {
        style: "percent",
        maximumFractionDigits: 1,
      })}
    ></Chip>
  );
}

function useChipProps(change: number): {
  icon?: ReactElement;
  color: ChipColor;
} {
  const theme = useTheme();
  if (change < 0) {
    return {
      color: "green",
      icon: (
        <ArrowNarrowDownSolid
          fontSize="inherit"
          style={{ color: theme.palette.green[800] }}
        />
      ),
    };
  }

  if (change > 0) {
    return {
      color: "red",
      icon: (
        <ArrowNarrowUpSolid
          fontSize="inherit"
          style={{ color: theme.palette.red[800] }}
        />
      ),
    };
  }

  return {
    color: "lightgrey",
  };
}
