import { makeStyles } from "@material-ui/core";
import { Typography, useBrand } from "@lumar/shared";
import success from "./animations/success.json";
import Lottie from "react-lottie-player";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
  },
  message: {
    color: theme.palette.grey[700],
  },
}));

export function ChartPanelSuccessMessage(props: {
  children: string;
  allowRefresh?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const brand = useBrand();

  return (
    <div className={classes.container}>
      {brand.featureAvailability.chartReportSerieColor ? (
        <div>
          <Lottie
            loop={false}
            animationData={success}
            play
            style={{
              width: "88px",
              height: "88px",
              margin: "auto",
            }}
          />
        </div>
      ) : null}
      <Typography component="div" variant="body2" className={classes.message}>
        {props.children}
      </Typography>
    </div>
  );
}
