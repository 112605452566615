import { BreadcrumbItem, ApolloError } from "@lumar/shared";
import React from "react";
import {
  CrawlContextValue,
  CrawlReportCategoryListNode,
  useCrawlContext,
} from "./CrawlContext";
import { useURLSearchParams } from "../../shared/routing/useURLSearchParams";
import { Routes } from "../../shared/routing/routes";

export interface CrawlOverviewContextValue {
  crawlContext: CrawlContextValue;
  loading: boolean;
  categoryNotFound: boolean;
  errors?: ApolloError[];
  helpers?: {
    selectCategory: (categoryCode: string) => void;
  };
  data?: {
    selectedCategory: CrawlReportCategoryListNode;
    breadcrumbs: BreadcrumbItem[];
  };
}

const CrawlOverviewContext =
  React.createContext<CrawlOverviewContextValue | null>(null);

export function useCrawlOverviewContext(): CrawlOverviewContextValue {
  const ctx = React.useContext(CrawlOverviewContext);

  if (ctx) {
    return ctx;
  }

  throw new Error(
    "`useCrawlOverviewContext` has been called without `CrawlOverviewContextProvider` in the tree!",
  );
}

export function useCrawlOverviewContextData(): NonNullable<
  CrawlOverviewContextValue["data"]
> {
  const ctx = React.useContext(CrawlOverviewContext);

  if (ctx === null) {
    throw new Error(
      "`useCrawlOverviewContextData` has been called without `CrawlOverviewContextProvider` in the tree!",
    );
  }

  if (!ctx.data) {
    throw new Error(
      "`useCrawlOverviewContextData` has been called while data wasn't loaded!",
    );
  }

  return ctx.data;
}

export function CrawlOverviewContextProvider(props: {
  children: React.ReactNode;
}): JSX.Element {
  const crawlContext = useCrawlContext();
  const { data, loading, errors } = crawlContext;
  const searchParams = useURLSearchParams();

  React.useEffect(() => window.scrollTo(0, 0), [searchParams]);

  const value = React.useMemo(() => {
    if (loading) {
      return {
        crawlContext,
        loading: true,
        categoryNotFound: false,
        errors,
        data: undefined,
        helpers: undefined,
      };
    }

    if (errors) {
      return {
        crawlContext,
        loading: false,
        categoryNotFound: false,
        errors,
        data: undefined,
        helpers: undefined,
      };
    }

    if (!data) {
      return {
        crawlContext,
        categoryNotFound: false,
        loading: false,
        errors,
        data: undefined,
        helpers: undefined,
      };
    }

    const requestedCategory =
      searchParams.get("category") ?? getDefaultTopReportCategory(data);

    const selectedCategory =
      data.crawlReportCategoriesList.find(
        (crawlReportCategory) => crawlReportCategory.code === requestedCategory,
      ) ?? data.crawlReportCategoriesTree[0];

    if (!selectedCategory) {
      return {
        crawlContext,
        categoryNotFound: true,
        loading: false,
        errors,
        data: undefined,
        helpers: undefined,
      };
    }

    return {
      crawlContext,
      loading: false,
      categoryNotFound: false,
      errors: undefined,
      helpers: {
        selectCategory(categoryCode: string): void {
          searchParams.set("category", categoryCode);
          searchParams.apply();
        },
      },
      data: {
        selectedCategory,
        breadcrumbs: createBreadcrumbs(data, selectedCategory),
      },
    };
  }, [loading, errors, data, crawlContext, searchParams]);

  React.useEffect(() => {
    if (!searchParams.get("category") && value.data?.selectedCategory) {
      searchParams.set("category", value.data.selectedCategory.code);
      searchParams.apply();
    }
  }, [searchParams, value]);

  return (
    <CrawlOverviewContext.Provider value={value}>
      {props.children}
    </CrawlOverviewContext.Provider>
  );
}

function createBreadcrumbs(
  crawlContextData: NonNullable<CrawlContextValue["data"]>,
  selectedCategory?: CrawlReportCategoryListNode,
): BreadcrumbItem[] {
  if (!selectedCategory) {
    return [];
  }

  const categoryLineage: CrawlReportCategoryListNode[] = [selectedCategory];

  // eslint-disable-next-line fp/no-loops
  while (categoryLineage[0]?.parentCode) {
    const parent = crawlContextData.crawlReportCategoriesList.find(
      (crawlReportCategory) =>
        crawlReportCategory.code === categoryLineage[0].parentCode,
    );
    if (parent) {
      // eslint-disable-next-line fp/no-mutating-methods
      categoryLineage.unshift(parent);
    } else {
      break;
    }
  }

  return categoryLineage.map((crawlReportCategory) => {
    return {
      label: crawlReportCategory.name,
      link: Routes.Crawl.getUrl({
        accountId: crawlContextData.crawlProject.account.rawID,
        projectId: crawlContextData.crawlProject.rawID,
        category: crawlReportCategory.code,
      }),
    };
  });
}

const prioritizedDefaultTopReportCategoryCodes = [
  "top", // top category for SEO crawls
  "accessibility", // top category for A11Y crawls
];

function getDefaultTopReportCategory(
  data: NonNullable<CrawlContextValue["data"]>,
): string | undefined {
  return prioritizedDefaultTopReportCategoryCodes.find((code) =>
    data?.crawlReportCategoriesTree.find(
      (topReportCategory) => topReportCategory.code === code,
    ),
  );
}
